@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #e3eeff;
}

/* custom scrollbar */

/* ::-webkit-scrollbar {
  width: 5px;
}

@media screen and (max-width: 700px) {
  ::-webkit-scrollbar {
    width: 2px;
  }
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: rgb(170, 170, 170);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(110, 110, 110);
} */

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.phone-number-fade {
  -webkit-animation: fadeIn 4s;
  animation: fadeIn 4s;
}

